.uploadContainer {
}

.uploadForm {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  margin-top: 2em;
  padding: 3em;
  padding-bottom: 1em;
}

.uploadContainer h2 {
  text-align: center;
  font: normal normal normal 24px/28px Roboto Condensed;
  letter-spacing: -1.2px;
  color: #2b2b2b;
  text-transform: uppercase;
}

.selectFilesText {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-bottom: 1em;
}

.formButtonsContainer {
  margin-top: 1em;
}

.formButtonsContainer * {
  margin: 0em 1em;
}

.originalFileInput {
  display: none;
}

.customFileInput {
  text-align: left;
  text-decoration: underline;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #3d4c70;
  cursor: pointer;
  display: inline-block;
}

.uploadedFilesContainer {
  font: normal normal 300 13px/24px Roboto;
  letter-spacing: -0.65px;
  color: #5d7997;
}

.uploadedFilesContainer span {
  display: inline-block;
  margin-left: 5px;
}

.uploadButton {
  border: 1px solid #5d7997;
  color: #5d7997;
  background-color: white;
  text-align: center;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  border-radius: 1px;
  cursor: pointer;
  padding: 4.5px 15.5px;
}

.uploadButton:disabled {
  border-color: #afafaf;
  color: #afafaf;
  cursor: default;
}

.uploaded {
  border: 1px solid #5d7997;
  color: white;
  background-color: #324b72;
  text-align: center;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  border-radius: 1px;
  cursor: pointer;
  padding: 4.5px 15.5px;
}

.uploadFilesText {
  margin-top: 25px;
  text-align: left;
  font: normal bold 300 18px/24px Roboto;
  letter-spacing: -0.8px;
  color: #3d4c70;
}
