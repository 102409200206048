.examplesContainer {
    margin-bottom: 2em;
}

.examplesDiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.examplesDiv > div {
    display: inline-block;
    width: 45%;
}

.examplesDiv > .examplesRemoveContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
}

.descriptionInput {
    min-height: 110px;
}

.examplesDataFormContainer hr {
    margin-top: 1em;
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#324b72;
    height: 1px;
}
  

/* bullet points */

  .bulletPointsContainer {
    
  }

  .bulletPointContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .bulletPointContainer div {
    margin-left: 15px;
  }

  .bulletPointContainer>* {
    justify-content: space-between;
  }

  /* FAQ */

  .faqContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .faqContainer .iconButtonContainer {
    margin-top: 16px;
    margin-left: 5px;
  }

  .faqElementsContainer {
    width: 100%;
  }

  .questionAndAnswerContainer {

  }

  /* Extra field button */

.addElementField {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.iconButtonContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #363f4c;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconButtonContainer i {
    color: #363f4c;
  }