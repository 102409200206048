.examplesContent {
  /* padding: 0 8em; */
}

.examplesContainer h1 {
  text-align: left;
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #fafafa;
  text-transform: uppercase;
  width: 100%;
  margin-left: 0.8em;
}

.examplesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1300px;
  width: 100%;
}

.exampleContainer {
  flex: 0 0 33%;
}

.exampleContent {
  margin: 1em 1em;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 4px #00000010;
  border-radius: 3px;
}

.exampleContent img {
  /* width: 100%;
  height: auto; */
  width: 397px;
  height: 280px;
  cursor: pointer;
}

.exampleTextContent {
  padding: 1.2em 9%;
  position: relative;
}

.exampleContent .title {
  text-align: left;
  font: normal normal bold 27px/32px Roboto Condensed;
  letter-spacing: -1.35px;
  color: #2b2b2b;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.exampleContent .title span {
  border-bottom: 2px solid #c1102b;
}

.exampleContent .subtitle {
  text-align: left;
  font: normal normal 300 14px/24px Roboto;
  letter-spacing: -0.7px;
  color: #2b2b2b;
  text-transform: uppercase;
}

.exampleContent .link {
  color: #c1102b;
  position: absolute;
  right: 10%;
  top: 20%;
  font-size: 23px;
  cursor: pointer;
}

/* Modal window css */

.overlayBase {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  opacity: 0.3;
  transition: opacity 500ms ease-in-out;
}

.overlayAfterOpen {
  opacity: 1;
}

.overLaybeforeClose {
  opacity: 0;
}

.modal {
  padding: 2em 3em;
  background: #fafafa 0% 0% no-repeat padding-box;
  max-width: 1008px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal .videoContainer {
  max-width: 1008px;
  max-height: 469px;
  height: 100%;
  width: 100%;
}

.reactPlayer {
  max-width: 1008px;
  max-height: 469px;
  height: 100%;
  width: 100%;
}

.reactPlayer > div {
  min-height: 469px;
  overflow: initial;
}

.reactPlayer > div > iframe {
  min-height: 469px;
  min-width: 100%;
}

.modal .buttonsContainer {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 34em;
}

.modal .previewButton {
  border: 1px solid #324b72;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #324b72;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px 70px;
  text-decoration: none;
}

.modal .closeButton {
  text-align: left;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #5d7997;
  text-transform: uppercase;
  height: 25px;
  cursor: pointer;
}

.modal .closeButton i {
  margin-left: 15px;
}
