.templateFilesContent {
    display: flex;
    padding: 5.5em 5.5em;
  }
  
  .tabContainer {
    flex: 0 0 20em;
  }
  
  .tabContent {
    flex: 1 0;
  }