.footer {
  display: flex;
  justify-content: center;
}

.footer.expanded {
  padding-bottom: 104px;
}

.footerContent {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #ffffff;
  margin: 0 1.5em;
  padding: 2em 8em;
  width: 1259px;
}
.footerContent a:first-child {
  margin-right: 25px;
}

.footer a {
  text-decoration: none;
}

.footerContent a:link {
  color: #ffffff;
}

.footerContent a:visited {
  color: #ffffff;
}

.footerContent a:hover {
  color: #dadada;
}

.footerContent a:active {
  color: #ffffff;
}

.footerContent span {
  float: right;
}

/* mobile css */

.footer.mobile .footerContent {
  padding: 1em 0 1.2em 0;
  margin: 0 3.5em;
}

.footer.mobile .footerContent a:first-child {
  text-align: left;
}

.footer.mobile .footerContent a:nth-child(2) {
  float: right;
}

.footer.mobile .footerContent span {
  text-align: center;
  width: 100%;
  padding: 1em 0;
}
