.stickyBar {
  position: fixed;
  bottom: -104px;
  left: 0;
  width: 100%;
  opacity: 0;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px -5px 10px #00000029;
  /* transition: bottom 1s ease-in;  */
  /* transition: opacity 1s  ease-in; */
  transition: all 1s;
  z-index: 2;
}

.stickyBar.active {
  bottom: 0;
  opacity: 1;
  transition: all 1s;
  /* transition: bottom 1s ease-out;  */
}

.stickyBarContent {
  display: flex;
  padding: 24px 68px;
  justify-content: space-between;
}

.stickyBar .selectTemplateButton {
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  background: #324b72 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  padding: 15px 0px;
  width: 280px;
  border-radius: 5px;
  cursor: pointer;
}

.stickyBar .selectTemplateButton:disabled {
  background: #afafaf 0% 0% no-repeat padding-box;
  border: 1px solid white;
}

.backButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.backButton .arrow {
  color: #c1102b;
  font-size: 23px;
  margin-bottom: 4px;
}

.backButton .text {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-left: 15px;
}
