.pageOne {
  display: flex;
  justify-content: space-between;
  /* padding: 5.5em 4.5em; */
}

.pageOne h1 {
  color: #363f4c;
  text-align: left;
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #363f4c;
  text-transform: uppercase;
}

.tabContainer {
  flex: 0 0 0em;
}

.tabContainer ul {
  margin-top: 1.8em;
}
.tabContent {
  flex: 0 0 0px;
  margin-left: auto;
  margin-right: 1.5em;
}

.rightBar {
  flex: 0 0 17em;
}

.rightBar .selectTemplateButton {
  margin-top: 94px;
  text-align: center;
  font: normal normal normal 22px/26px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  background: #324b72 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  padding: 15px 0px;
  width: 280px;
  border-radius: 5px;
  cursor: pointer;
}

.rightBar .selectTemplateButton:disabled {
  background: #afafaf 0% 0% no-repeat padding-box;
  border: 1px solid white;
}

/* mobile css */

.pageOne.mobile {
  /* padding: 1em; */
}

.mobileContentContainer h1 {
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #363f4c;
  text-transform: uppercase;
}

.introductionText {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  white-space: break-spaces;
}

.bulletPoint {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.bulletPoint .bullet {
  background-color: #5d7997;
  height: 12px;

  padding: 6px 6.5px 4px 6.5px;
  border-radius: 100px;
  color: white;
  margin-right: 20px;
  font-size: 10px;
}

.bulletPoint .point {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
}

.mobileWarning {
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #c1102b;
  text-align: center;
  margin-bottom: 1em;
}

.bulletPointsContainer {
  margin: 2em 0;
}
