.header {
  background: transparent linear-gradient(180deg, #2f2f2f 0%, #2f2f2f1a 100%) 0%
    0% no-repeat padding-box;
  /* opacity: 1; */
  margin: 0;
  display: flex;
  justify-content: center;
}

.headerContent {
  position: relative;
  width: 1376px;
  height: 100px;
}

.logo {
  height: 55px;
  left: 100px;
  top: 23px;
  position: absolute;
}

/* mobile css */

.header.mobile .logo {
  left: 2em;
  top: 30px;
}

.ezRichMediaheader {
  font: normal normal bold 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #fafafa;
  text-shadow: 0px 1px 6px #00000064;
  text-transform: uppercase;
  display: inline;
  margin-right: 1.1em;
  white-space: nowrap;
  align-self: flex-end;
}
