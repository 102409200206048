.descriptionInput {
  height: 10em;
}

.homePageDataFormContainer {
    margin-bottom: 1em;
}

/* bullet points */

.bulletPointContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bulletPointContainer div {
  margin-left: 15px;
}

.bulletPointContainer>* {
  justify-content: space-between;
}

  /* Extra field button */

  .addElementField {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.iconButtonContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #363f4c;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconButtonContainer i {
    color: #363f4c;
  }
