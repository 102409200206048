/* Modal window css */

.overlayBase {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  opacity: 0.3;
  transition: opacity 500ms ease-in-out;
  font: normal normal 300 16px/20px Roboto;
}

.overlayAfterOpen {
  opacity: 1;
}

.overLaybeforeClose {
  opacity: 0;
}

.modal {
  padding: 2em 3em;
  background: #fafafa 0% 0% no-repeat padding-box;
  max-width: 1008px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal .closeButton {
  text-align: left;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #5d7997;
  text-transform: uppercase;
  height: 25px;
  cursor: pointer;
  padding-bottom: 60px;
}

.modal .closeButton i {
  margin-left: 15px;
}

/* delete mail button css */

.margin {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.spacingSmall {
  margin-top: 30px;
}

.spacingSmall h2 {
  margin-bottom: 15px;
}

.spacingSmall > div {
  margin-bottom: 7px;
}

.key {
  font-weight: 600;
}

ul {
  margin: 5px 0;
}

.button {
  border: 1px solid #5d7997;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #5d7997;
  max-width: 120px;
  text-align: center;
  padding: 5px 20px;
  text-decoration: none;
  cursor: pointer;
}

.viewMailButton {
  background-color: white;
  margin: 0 0.5em;
}
