.introductionDiv {
}

.introductionText {
  width: 600px;
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  white-space: break-spaces;
}

.bulletPoint {
  margin-bottom: 10px;
}

.bulletPoint .bullet {
  background-color: #5d7997;
  width: 20px;
  height: 20px;
  padding: 4px 6.5px;
  border-radius: 100px;
  color: white;
  margin-right: 20px;
  font-size: 10px;
}

.bulletPoint .point {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
}
