.uploadsContainer {
  padding: 1em 4em;
}

.uploadHistoryTable {
  width: 100%;
  border-spacing: 0 1em;
}

.resultsContainer {
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 3px;
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: 0.8px;
  height: 70px;
}

tbody .validity {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  padding: 15px;
}
.pinned {
  text-align: center;
}

.emptySpace {
  margin: 0 0.5em;
  min-width: 100px;
}

.downloaded {
  text-align: center;
  padding: 15px;
}

.templateName {
  text-align: center;
}

.templateId {
  text-align: center;
}

.creationDate {
  text-align: center;
}

.actions {
  text-align: center;
}

.validityIconContainer {
  background-color: #5d7997;
  width: 20px;
  height: 20px;
  padding: 4px 4px;
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  position: relative;
}

.validityIconContainer i {
  position: absolute;
  left: 9px;
  top: 7px;
  font-size: 15px;
}

.validityIconContainer i.checkMark {
  left: 7px;
  font-size: 14px;
}

tbody .actions div {
  display: flex;
  justify-content: center;
}

.button {
  border: 1px solid #5d7997;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #5d7997;
  max-width: 120px;
  text-align: center;
  padding: 5px 20px;
  text-decoration: none;
  cursor: pointer;
}

.button.disabled {
  color: #afafaf;
  border-color: #afafaf;
  cursor: default;
}

.previewButton {
}

.viewMailButton {
  background-color: white;
  margin: 0 0.5em;
}

.downloadButton {
  margin: 0 0.5em;
}

.resultsHeaders {
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #667895;
  text-transform: uppercase;
  margin: 0 1em;
}
