html,
body,
#root {
  height: 100%;
}

.App {
  background: transparent linear-gradient(232deg, #030a15 0%, #44546c 100%) 0%
    0% no-repeat padding-box;
  /* opacity: 1; */
  min-height: 100%;
  /* height: 100%; */
}

.App.mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
