.inputGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }


.businessUnitsContainer {
    margin-bottom: 2em;
}

.businessUnitContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.businessUnitContainer > div {
    display: inline-block;
    width: 45%;
}

.businessUnitContainer > .examplesRemoveContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  width: 100%;
}

.descriptionInput {
    min-height: 110px;
}

.examplesDataFormContainer hr {
    margin-top: 1em;
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#324b72;
    height: 1px;
}
  

  /* Extra field button */

  .addElementField {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.iconButtonContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #363f4c;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconButtonContainer i {
    color: #363f4c;
  }