/** Used to define container behavior: width, position: fixed etc... **/
.App .Toastify__toast-container {
  min-width: 20em;
  width: auto;
  white-space: break-spaces;
}

/** Used to define the position of the ToastContainer **/
.App .Toastify__toast-container--top-left {
}
.App .Toastify__toast-container--top-center {
}
.App .Toastify__toast-container--top-right {
}
.App .Toastify__toast-container--bottom-left {
}
.App .Toastify__toast-container--bottom-center {
}
.App .Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.App .Toastify__toast {
  color: #fff;
  font: normal normal normal 17px/21px Roboto Condensed;
}
.App .Toastify__toast--rtl {
}
.App .Toastify__toast--dark {
}
.App .Toastify__toast--default {
}
.App .Toastify__toast--info {
  background: #324b72;
}
.App .Toastify__toast--success {
  background: #5d7997;
}
.App .Toastify__toast--warning {
  background: #ffb444;
}
.App .Toastify__toast--error {
  background: #c1102b;
}
.App .Toastify__toast-body {
}

/** Classes for the close button. Better use your own closeButton **/
.App .Toastify__close-button {
}
.App .Toastify__close-button--default {
}
.App .Toastify__close-button > svg {
}
.App .Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.App .Toastify__progress-bar {
}
.App .Toastify__progress-bar--animated {
}
.App .Toastify__progress-bar--controlled {
}
.App .Toastify__progress-bar--rtl {
}
.App .Toastify__progress-bar--default {
}
.App .Toastify__progress-bar--dark {
}
