.accordionDiv {
  width: 592px;
  margin-bottom: 0.7em;
  cursor: pointer;
}

.accordionButton {
  font: normal normal 300 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #324b72;
  text-transform: uppercase;
  width: 592px;
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
  display: inline-block;
}

.accordionButtonText {
  /* position: absolute; */
  text-align: left;
  font: normal normal 300 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #324b72;
  text-transform: uppercase;
  padding: 20px 0px 20px 20px;
  display: inline-block;
}

.accordionButtonArrow {
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 600;
  font-family: 'Roboto';
  cursor: pointer;
  transform: rotate(-360deg);
  transition: transform 400ms ease;
}

.accordionButtonArrow.active {
  transform: rotate(-180deg);
  transition: transform 400ms ease;
}

.accordionContent {
  padding: 20px 20px 20px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.accordionContent.open {
}

.accordionContent span {
  width: 100%;
  display: block;
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 5px 5px 5px 20px;
}
