.resultsHeaderContainer {
  display: flex;
  justify-content: space-between;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #667895;
  text-transform: uppercase;
  width: 100%;
  margin: 4em 0 2em 0;
}

.resultsHeaderContainer span:nth-child(1) {
  display: inline;
}

.resultsHeaderContainer span:nth-child(2) {
  display: inline;
}

.resultsContainer {
}

.resultContainer {
  display: flex;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 1em 0em;
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  margin-bottom: 1em;
}

.fileName {
  color: #363f4c;
  margin-left: 1em;
}

.resultContainer .accepted {
  color: #5d7997;
  margin-right: 1em;
}

.resultContainer .error {
  color: #c1102b;
  margin-right: 1em;
}

.resultContainer .notUploaded {
  color: #afafaf;
  margin-right: 1em;
}

.nextStepsButtonContainers {
  display: flex;
  justify-content: space-evenly;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin-top: 2em;
  height: 50px;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
}

.nextStepsButtonContainers div,
.nextStepsButtonContainers a {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.previewButton {
  cursor: pointer;
  border: 1px solid #324b72;
  color: #324b72;
  background-color: white;
  text-decoration: none;
  text-align: center;
}

.previewButton.disabled {
  cursor: initial;
  border: 1px solid #afafaf;
  color: #afafaf;
}

.notifyButton {
  cursor: pointer;
  background: #324b72 0% 0% no-repeat padding-box;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font: normal normal normal 20px/24px Roboto Condensed;
}

.notifyButton.disabled {
  cursor: initial;
  background: #afafaf 0% 0% no-repeat padding-box;
  border: none;
  color: #ffffff;
}

.downloadCreativeButton {
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  background: #324b72 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  cursor: pointer;
  display: flex;
}

.downloadCreativeButton .smallText {
  font-size: 16px;
  font-weight: lighter;
  text-transform: lowercase;
}

.downloadCreativeButton.disabled {
  cursor: initial;
  background: #afafaf 0% 0% no-repeat padding-box;
  border: none;
  color: #ffffff;
}
