h1, h2, h3, h4, h5 {
    margin: 0;
}

.filesContainers {
    display: flex;
    flex-wrap: wrap;
}

.fileButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0;
}

.fileButtonContainer > span {
    font: normal normal normal 18px/24px Roboto Condensed;
    letter-spacing: -1px;
    color: #5d7997;
    text-transform: uppercase;
    margin-right: 4px;
}

.fileList {
    margin-bottom: 1em;
}

/* Upload form css */

.uploadForm {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  padding-bottom: 1em;
  max-width: 30em;
  border: 1px solid #3d4c70;
  margin-bottom: 2em;
  text-align: center !important;
}

.uploadContainer h2 {
  text-align: center;
  font: normal normal normal 24px/28px Roboto Condensed;
  letter-spacing: -1.2px;
  color: #2b2b2b;
  text-transform: uppercase;
}

.formHeader {
    color: #3d4c70;
    font: normal normal normal 20px/20px Roboto Condensed;
    margin-bottom: 0.5em;
}

.radioButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttonHolder {
    display: flex;
    justify-content: space-between;
    width: 5em;
    margin: 0.5em 0;
}

.uploadButtonContainer {
    display: flex;
    justify-content: center;
}

.uploadButtonHolder {
    width: 30%;
}

.selectFilesText {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-bottom: 1em;
}

.formButtonsContainer {
  margin-top: 1em;
}


.originalFileInput {
  display: none;
}

.customFileInput {
  text-align: left;
  text-decoration: underline;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #3d4c70;
  cursor: pointer;
  display: inline-block;
}

.uploadedFilesContainer {
  font: normal normal 300 13px/24px Roboto;
  letter-spacing: -0.65px;
  color: #5d7997;
}

.uploadedFilesContainer span {
  display: inline-block;
  margin-left: 5px;
}

.uploadButton {
  border: 1px solid #5d7997;
  color: #5d7997;
  background-color: white;
  text-align: center;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  border-radius: 1px;
  cursor: pointer;
  padding: 4.5px 15.5px;
}

.uploadButton:disabled {
  border-color: #afafaf;
  color: #afafaf;
  cursor: default;
}

.uploaded {
  border: 1px solid #5d7997;
  color: white;
  background-color: #324b72;
  text-align: center;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  border-radius: 1px;
  cursor: pointer;
  padding: 4.5px 15.5px;
}

.uploadFilesText {
  margin-top: 25px;
  text-align: left;
  font: normal bold 300 18px/24px Roboto;
  letter-spacing: -0.8px;
  color: #3d4c70;
}

/* fonts */

.filesDataFormContainer label {
  text-align: left;
    font: normal normal 300 16px/24px Roboto;
    letter-spacing: -0.8px;
    color: #363f4c;
    white-space: break-spaces;
    font-weight: 500;
}

.downloadButton {
  margin-left: 10px;
  border: 1px solid #5d7997;
    font: normal normal 300 16px/24px Roboto !important;
    letter-spacing: -0.8px;
    color: #5d7997;
    text-align: center;
    padding: 5px 21.2px;
    text-decoration: none;
    cursor: pointer;
    text-transform: initial !important;
}

.uploadsContainer > * {
  margin-top: 1em;
  font-size: 16px;
  font-weight: bold;
}