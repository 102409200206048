.specificationsContainer {
  width: 592px;
}

.specContainer {
  margin-bottom: 10px;
  display: flex;
}

.specName {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #324b72;
  padding: 15px;
  flex: 1 1 25%;
}

.specInfo {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 15px;
  flex: 1 1 75%;
}
