.formFieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.inputGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inputContainer {
  display: inline-block;
  width: 45%;
  /* margin: 1em 0; */
}

.elementFieldsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.elementFieldsContainer>div {
  width: 45%;
  display: inline-block;
}

.elementInputsContainer {
  position: relative;
}

.iconButtonContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #363f4c;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  position: absolute;
  cursor: pointer;
  right: -37px;
  top: 11px;
}

/* Add button css overrides */

.iconButtonContainer i {
  position: absolute;
  left: 5px;
  top: 4px;
  color: #363f4c;
}

.elementFieldsContainer>div.addElementField {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addElementField>.iconButtonContainer {
  position: relative;
  right: initial;
  top: initial;
}

.addElementField>.iconButtonContainer i {
  left: 5.5px;
}

/* Checkbox css */

.checkBoxesContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.checkBoxContainer {}

.checkBoxContainer label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
}

.checkBoxContainer label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkBoxContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #324b72;
  border-radius: 3px;
}

.checkBoxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkBoxContainer label input:checked ~ .checkmark:after {
  display: block;
}

.checkBoxContainer label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #324b72;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkBoxContainer>* {
  display: inline-block;
  width: auto;
}

.checkBoxContainer>label {
  /* min-width: 5em; */
}

.checkBoxContainer>input {
  margin-left: 7px;
  margin-right: 50px;
}

/* End checkbox css */

.filesDataFormContainer hr {
  margin-top: 2em;
  border: 0;
  clear:both;
  display:block;
  width: 96%;               
  background-color:#324b72;
  height: 1px;
}


  /* fonts */

  .filesDataFormContainer label {
    text-align: left;
    font: normal normal 300 16px/24px Roboto;
    letter-spacing: -0.8px;
    color: #363f4c;
    white-space: break-spaces;
    font-weight: 500;
  }

  .plusButtonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  align-items: center;
  }