.templateDataContent {
  display: flex;
  padding: 5.5em 5.5em;
}

.tabContainer {
  flex: 0 0 20em;
}

.tabContent {
  flex: 1 0;
}

h2,
h3,
h4,
h5,
h6 {
  width: 100%;
  margin: 20px 0;
  text-align: left;
  font: normal normal normal Roboto Condensed;
  letter-spacing: -1.2px;
  color: #3d4c70;
  text-transform: uppercase;
}


label {
  display: block;
}

input, textarea {
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 0.7em 1em;
  margin-bottom: 0.2em;
  width: 100%;
  box-sizing: border-box;
}

input[disabled] {
  background-color: rgb(206, 206, 206);
}


select {
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 0.7em 1em;
  margin-bottom: 0.2em;
  text-transform: none;
  width: 100%;
  box-sizing: border-box;
}

  /* Extra field button */

  .addElementField {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.iconButtonContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #363f4c;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconButtonContainer i {
    color: #363f4c;
  }

/* modal window */

/* Modal window css */

.overlayBase {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  opacity: 0.3;
  transition: opacity 500ms ease-in-out;
}

.overlayAfterOpen {
  opacity: 1;
}

.overLaybeforeClose {
  opacity: 0;
}

.modal {
  padding: 2em 3em;
  background: #fafafa 0% 0% no-repeat padding-box;
  max-width: 1008px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 35em;
}

.modal .closeButton {
  text-align: left;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #5d7997;
  text-transform: uppercase;
  height: 25px;
  cursor: pointer;
}

.modal .closeButton i {
  position: absolute;
  left: 23px;
  top: 22px;
  font-size: 24px;
}

.deleteTemplateButton {
  /* color: red; */
  /* font-weight: bold; */
}

.modal .templateName {
  color: #58678b;
  text-decoration: underline;
}

.modal h2 {
  text-align: center;
}

.modal .ConfirmationButtonContainer {
  justify-content:center;
  display: flex;
}