.downloadLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.2em;
}

.downloadLinksContainer .linkContainer {
  margin-bottom: 1.5em;
}

.downloadLinksSidebarContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2.2em;
  margin-bottom: 1em;
  background-color: white;
  padding: 2em;
}

.linkSidebarContainer {
  margin-bottom: 2.2em;
}

.downloadLinksLargeButton {
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  background: #324b72 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  width: 280px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.downloadLinksLargeButton p {
  margin: 8px;
}

h2 {
  text-align: left;
  font: normal normal normal 28px/32px Roboto Condensed;
  letter-spacing: -1.2px;
  color: #3d4c70;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  margin-top: -0.5em;
}

.buttonTextSmall {
  font-size: 16px;
  font-weight: lighter;
  text-transform: lowercase;
}

/* .downloadLinksContainer .linkContainer a {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #324b72;
  display: block;
  text-decoration: none;
}

.downloadLinksContainer .linkContainer a i {
  margin-right: 3px;
}

.downloadLinksContainer .linkContainer span {
  text-align: left;
  font: normal normal 300 14px/24px Roboto;
  letter-spacing: -0.7px;
  color: #2b2b2b;
  display: block;
} */
