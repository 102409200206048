.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* height: 100%; */
  /* min-height: 100%; */
  min-height: 100vh;
}

.homeContainer:nth-child(2) {
  flex-grow: 1;
}

.stepContent {
  /* background-color: white; */
  margin: 1em 32px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 3px;
  max-width: 1376px;
  width: 100%;

  flex-grow: inherit;
}

.stepContent > div {
  padding: 5.5em 5.5em;
}

/* mobile css */

.body.mobile {
  margin: 0 2em;
}

.body.mobile .stepContent > div {
  padding: 0em 0.2em;
  margin: 0 1em;
}

.loadingIcon:before {
  /* position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: rgba(255,255,255,.8);
    width: 100%;
    height: 100%;
    border-radius: .28571429rem;
    z-index: 100; */
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingIcon:after {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -3em 0 0 -5em;
  width: 10em;
  height: 10em;
  border-radius: 500rem;
  border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.3em;
  box-shadow: 0 0 0 1px transparent;
  visibility: visible;
  z-index: 101;
}
