.buttonsContainer {
  display: flex;
}

.button {
  border: 1px solid #5d7997;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #5d7997;
  text-align: center;
  padding: 5px 21.2px;
  text-decoration: none;
  cursor: pointer;
}

.button.initial {
}

.button.confirm {
  color: #324b72;
  border-color: #324b72;
  /* color: #07bc0c;
    border-color: #07bc0c; */
  padding: 5px 10px;
}

.button.cancel {
  color: #324b72;
  border-color: #324b72;
  /* color: #c1102b;
    border-color: #c1102b; */
  margin-left: 0.5em;
  padding: 5px 13px;
}
