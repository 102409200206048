.stepNavigation {
  max-width: 1376px;
  width: 100%;
}

.stepNavigation h1 {
  text-align: left;
  font: normal normal bold 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #fafafa;
  text-shadow: 0px 1px 6px #00000064;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 100px;
  margin-right: 100px;
}

.stepContainer {
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}

.stepContainer .numberContainer {
  background-color: #afafaf;
  color: #fafafa;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  font: normal normal bold 21px/24px Roboto Condensed;
}

.textContainer {
  display: inline-block;
  height: 32px;
  vertical-align: top;
  margin-left: 15px;
  margin-right: 30px;
  margin-top: 3px;
}

.stepContainer .numberContainer span {
  top: 4px;
  left: 10.5px;
  position: absolute;
}

.stepContainer .text {
  color: #afafaf;
  text-align: left;
  font: normal normal 300 24px/28px Roboto Condensed;
}

.stepContainer.active .numberContainer {
  background-color: #ffffff;
  color: #5d7997;
}

.stepContainer.active .text {
  color: #ffffff;
}
