.pageFour {
  display: flex;
  justify-content: center;
}

.pageFour .content {
  flex: 0 0 500px;
}

.pageFour h1 {
  text-align: left;
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #363f4c;
  text-transform: uppercase;
}

.pageFour .contactText {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-top: 1em;
  margin-bottom: 2em;
}

.pageFour input {
  width: 332px;
  height: 56px;
  border: 1px solid #324b72;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #324b72;
  text-transform: uppercase;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 2em;
  cursor: pointer;
}

.backButton {
  margin-bottom: 1.5em;
  cursor: pointer;
}

.backButton .arrow {
  color: #c1102b;
}

.backButton .text {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-left: 15px;
}
