.pageTwo {
  display: flex;
}

.pageTwo > div {
  flex: 1 0 0;
}

.pageTwo .tabContent {
  flex: 0 0 592px;
  margin-left: auto;
  margin-right: 1.5em;
}

.pageTwo .rightBar {
  margin-top: 3.2em;
}

.pageTwo h1 {
  color: #363f4c;
  text-align: left;
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1.5em;
}

.backButton {
  margin-bottom: 3.7em;

  margin-top: 0.5em;
  cursor: pointer;
}

.backButton .arrow {
  color: #c1102b;
}

.backButton .text {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-left: 15px;
}

.selectedFormatBar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 1em 1.5em;
  width: 544px;
  height: 26px;
  position: relative;
  margin-bottom: 1em;
}

.selectedFormatBar .checkmarkContainer {
  border-radius: 100px;
  color: #324b72;
  background-color: white;
  border: 1px solid #324b72;
  width: 24px;
  height: 24px;
  position: absolute;
  display: inline-block;
}

.selectedFormatBar .checkmarkContainer i {
  position: absolute;
  left: 6px;
  top: 6px;
  font-size: 13px;
}

.selectedFormatBar .selectedFormat {
  text-align: left;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #667895;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  left: 65px;
}
