.adminPage {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* height: 100%; */
  /* min-height: 100%; */
  min-height: 100vh;
}

.adminPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.adminPageContent {
  margin: 1em 32px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 3px;
  max-width: 1376px;
  width: 100%;
  flex-grow: 1;
}

.stepNavigation {
  max-width: 1376px;
  width: 100%;
}

.stepNavigation h1 {
  text-align: left;
  font: normal normal bold 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #fafafa;
  text-shadow: 0px 1px 6px #00000064;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 100px;
  margin-right: 100px;
}

.stepContainer {
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}

.stepContainer .iconContainer {
  background-color: #afafaf;
  color: #fafafa;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  font: normal normal bold 21px/24px Roboto Condensed;
}

.textContainer {
  display: inline-block;
  height: 32px;
  vertical-align: top;
  margin-left: 15px;
  margin-right: 30px;
  margin-top: 3px;
}

.stepContainer .iconContainer span {
  top: 4px;
  left: 9.5px;
  position: absolute;
  font-size: 18px;
}

.stepContainer:nth-child(4) .iconContainer span {
  left: 8px;
}

.stepContainer .text {
  color: #afafaf;
  text-align: left;
  font: normal normal 300 24px/28px Roboto Condensed;
}

.stepContainer.active .numberContainer {
  background-color: #ffffff;
  color: #5d7997;
}

.stepContainer.active .text {
  color: #ffffff;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingIcon:after {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -3em 0 0 -5em;
  width: 10em;
  height: 10em;
  border-radius: 500rem;
  border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.3em;
  box-shadow: 0 0 0 1px transparent;
  visibility: visible;
  z-index: 101;
}
