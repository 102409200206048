.stepTabContainer {
}

.tabList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.tabList li {
  margin-bottom: 35px;
}

.tabList li > * {
  cursor: pointer;
}

.stepTabListItem {
  text-align: left;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #5d7997;
  text-transform: uppercase;
  text-decoration: none;
}

.stepTabListItem.active {
  border-bottom: 2px solid #c1102b;
  color: #2b2b2b;
}
