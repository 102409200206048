.pageTwo {
  display: flex;
  justify-content: space-between;
}
.pageTwo > div {
  flex: 0 0 auto;
}

.backButtonContainer {
}

.backButton {
  margin-bottom: 3.7em;
  margin-top: 1.8em;
  cursor: pointer;
}

.backButton .arrow {
  color: #c1102b;
}

.backButton .text {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  margin-left: 15px;
}

.mailFormContainer {
  width: 700px;
}

.mailFormContainer h1 {
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #363f4c;
  text-transform: uppercase;
}

.mailFormContainer h2 {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
}

.missingFieldText {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #c1102b;
}

.inputContainer {
  display: inline-block;
}

.nameAndMailContainer {
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.mailFormContainer label {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  display: block;
}

.mailFormContainer input {
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 0.7em 1em;
  width: 293px;
}

.mailFormContainer textarea {
  width: 666px;
  height: 159px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 0.7em 1em;
}

.ccFieldsContainer {
  display: flex;
}

.ccFieldsInputsContainer input {
  margin-bottom: 1em;
}

.addCcFieldButtonContainer {
  position: relative;
}


.addCcField {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #363f4c;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  position: absolute;
  bottom: 26px;
  left: 15px;
  cursor: pointer;
}

.addCcField i {
  position: absolute;
  left: 5px;
  top: 4px;
  color: #363f4c;
}



.landingPageFieldsContainer {
  display: flex;
}

.landingPageInputsContainer input {
  margin-bottom: 1em;
}

.addLandingPageFieldButtonContainer {
  position: relative;
}

.addLandingPageField {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #363f4c;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  position: absolute;
  bottom: 26px;
  left: 15px;
  cursor: pointer;
}

.addLandingPageField i {
  position: absolute;
  left: 5px;
  top: 4px;
  color: #363f4c;
}


.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1em;
}

.buttonsContainer > span {
  color: #324b72;
  font-size: 18px;
}

.checkCampaignButton {
  cursor: pointer;
  background: #324b72 0% 0% no-repeat padding-box;
  border: none;
  width: 272px;
  height: 56px;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px;
}

.downloadCampaignButton {
  cursor: pointer;
  border: 1px solid #324b72;
  color: #324b72;
  width: 272px;
  height: 56px;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none;
  line-height: 56px;
  background-color: white;
}

.inputContainer.invalid input {
  color: #c1102b;
  border: 1px solid #c1102b;
}

.inputContainer.invalid label {
  color: #c1102b;
}

.ccFieldsContainer.invalid label {
  color: #c1102b;
}

.ccFieldsContainer input.invalid {
  color: #c1102b;
  border: 1px solid #c1102b;
}

.landingPageFieldsContainer.invalid label {
  color: #c1102b;
}

.landingPageFieldsContainer input.invalid {
  color: #c1102b;
  border: 1px solid #c1102b;
}

.selectInputContainer {
  width: 50%;
  display: inline-block;
  margin-bottom: 1em;
}

.selectInput {
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #324b72;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 0.7em 1em;
  width: 292px;
}

/* .ccFieldsContainer.invalid input.valid {
  color: #324b72;
} */

/* Submitted info css  */

.submittedInfoContainer {
}

.submittedInfoContainer h1 {
  text-align: left;
  font: normal normal normal 32px/38px Roboto Condensed;
  letter-spacing: -1.6px;
  color: #363f4c;
  text-transform: uppercase;
}

.submittedInfoContainer h2 {
  text-align: left;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
}

.confirmationInfoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.infoName {
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #324b72;
  flex: 0 0 168px;
  padding: 1em;
  margin-right: 1em;
}

.flex {
  flex: 0 0 168px;
}

.infoValue {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal 300 16px/24px Roboto;
  letter-spacing: -0.8px;
  color: #363f4c;
  padding: 1em;
  flex: 1 0 0;
  width: 607px;
  min-height: 24px;
  word-wrap: break-word;
  max-width: 608px;
}

.editInfoButton {
  text-decoration: none;
  width: 286px;
  height: 56px;
  border-radius: 5px;
  background: #324b72 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 1em;
}

.submitButton {
  width: 286px;
  height: 56px;
  border-radius: 5px;
  background: #c1102b 0% 0% no-repeat padding-box;
  border: 1px solid #c1102b;
  text-align: center;
  font: normal normal normal 20px/24px Roboto Condensed;
  letter-spacing: -1px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}